import React, { Component } from "react";

import withAuth from "../hoc/withAuth";
import MainLayout from "../components/layouts/MainLayout";

import { uploadDocumentService } from "../services/UploadDocumentService";

import { Form, Button, Input, Upload, Modal, Row, Col, Space, Select } from "antd";
import { EditOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";

class DocumentsUploadBpkb extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;
    const code_svb = match.params.code_svb;

    this.initState = {
      codeSVB: code_svb,
      loadingButton: false,

      editMode: false,
      editButtonText: "Edit",

      inputCategory: "motorcycle",
      inputDocYear: "",
      inputReason: "",

      resultObject: {},
      resultBpkbNumber: "",
      resultPlateNumber: "",
      resultEngineNumber: "",
      resultChassisNumber: "",
      resultCustomerName: "",
      resultCustomerIdNumber: "",
      resultPhoneNumber: "",
      resultEmail: "",
      resultAddress: "",
      resultCategory: "",
      resultBrandName: "",
      resultVariantCode: "",
      resultVehicleColor: "",
      resultManufactureYear: "",

      fileList: [],
      uploading: false,
      uploadedImages: [],
    };

    this.state = { ...this.initState };
    this.formInput = React.createRef();
  }

  handleFieldChange = (field, value) => {
    // console.log("Input =", this.state.resultBbnkb);
    // console.log("Result API =", this.state.resultObject?.bbnkb);
    this.setState({ [field]: value });

    // console.log("Input =", this.state.resultBbnkb);
    // console.log("Result API =", this.state.resultObject?.bbnkb);
  };

  onExtractDokumen = async () => {
    try {
      const { inputCategory, inputDocYear, fileList } = this.state;

      this.setState({
        loadingButton: true,
      });

      const formData = new FormData();

      formData.append("category", inputCategory);
      formData.append("document_year", inputDocYear);

      fileList.forEach((file) => {
        formData.append("documents", file.originFileObj);
      });

      let extractedData = await uploadDocumentService.extractBpkb(formData);
      this.setState({
        loadingButton: false,
        resultObject: extractedData?.data?.data,

        resultBpkbNumber: extractedData?.data?.data?.bpkb_number,
        resultPlateNumber: extractedData?.data?.data?.plate_number,
        resultEngineNumber: extractedData?.data?.data?.engine_number,
        resultCustomerName: extractedData?.data?.data?.customer_name,
        resultCustomerIdNumber: extractedData?.data?.data?.customer_id_number,
        resultPhoneNumber: extractedData?.data?.data?.phone_number,
        resultEmail: extractedData?.data?.data?.email,
        resultAddress: extractedData?.data?.data?.address,
        resultCategory: extractedData?.data?.data?.category,
        resultBrandName: extractedData?.data?.data?.brand_name,
        resultChassisNumber: extractedData?.data?.data?.chassis_number,
        resultVehicleColor: extractedData?.data?.data?.vehicle_color,
        resultVariantCode: extractedData?.data?.data?.variant_code,
        resultManufactureYear: extractedData?.data?.data?.manufacture_year,
      });
    } catch (error) {
      this.setState({
        loadingButton: false,
      });

      if (error.message) {
        Modal.error({
          title: "Process Error",
          content: error.message,
        });
      } else {
        Modal.error({
          title: "Process Error",
          content: "Error Timeout",
        });
      }
    }
  };

  onChangeFileList = ({ fileList }) => {
    this.setState({ fileList });
  };

  onActiveEdit = () => {
    try {
      this.setState({
        editMode: true,
        inputReason: "",
      });
    } catch (error) {
      this.setState({
        loadingButton: false,
      });
      Modal.error({
        title: "Process Error",
        content: error.message,
      });
    }
  };

  onCancelEdit = () => {
    const { resultObject } = this.state;

    this.setState({
      editMode: false,
      resultBpkbNumber: resultObject?.bpkb_number,
      resultPlateNumber: resultObject?.plate_number,
      resultEngineNumber: resultObject?.engine_number,
      resultCustomerName: resultObject?.customer_name,
      resultCustomerIdNumber: resultObject?.customer_id_number,
      resultPhoneNumber: resultObject?.phone_number,
      resultEmail: resultObject?.email,
      resultAddress: resultObject?.address,
      resultCategory: resultObject?.category,
      resultBrandName: resultObject?.brand_name,
      resultChassisNumber: resultObject?.chassis_number,
      resultVehicleColor: resultObject?.vehicle_color,
      resultVariantCode: resultObject?.variant_code,
      resultManufactureYear: resultObject?.manufacture_year,
      inputReason: "",
    });
  };

  onSaveData = async () => {
    try {
      const { ...currentState } = this.state;
      // console.log("X", is_data_valid);
      // return;

      if (Object.keys(currentState.resultObject).length < 1) {
        throw new Error("Belum mengextract data");
      }

      if (!currentState.resultBpkbNumber) {
        throw new Error("Nomor BPKB tidak valid");
      }
      if (!currentState.resultPlateNumber) {
        throw new Error("Nomor Polisi tidak valid");
      }
      if (!currentState.resultEngineNumber) {
        throw new Error("Nomor Mesin tidak valid");
      }
      if (!currentState.resultCustomerName) {
        throw new Error("Nama Konsumen tidak valid");
      }
      if (!currentState.resultCustomerIdNumber) {
        throw new Error("Nomor KTP Konsumen  tidak valid");
      }

      if (currentState.editMode === true && !currentState.inputReason) {
        throw new Error("Alasan tidak valid");
      }

      let document_asset = [];
      const formData = new FormData();

      formData.append("bpkb_number", currentState.resultBpkbNumber);
      formData.append("plate_number", currentState.resultPlateNumber);
      formData.append("engine_number", currentState.resultEngineNumber);
      formData.append("chassis_number", currentState.resultChassisNumber);
      formData.append("customer_name", currentState.resultCustomerName);
      formData.append("customer_id_number", currentState.resultCustomerIdNumber);
      formData.append("address", currentState.resultAddress);
      formData.append("phone_number", currentState.resultPhoneNumber);
      formData.append("email", currentState.resultEmail);
      formData.append("category", currentState.resultCategory);
      formData.append("brand_name", currentState.resultBrandName);
      formData.append("variant_code", currentState.resultVariantCode);
      formData.append("vehicle_color", currentState.resultVehicleColor);
      formData.append("manufacture_year", currentState.resultManufactureYear);
      formData.append("document_asset", document_asset);
      formData.append("edit_mode", currentState.editMode);
      formData.append("edit_reason", currentState.inputReason);

      currentState.fileList.forEach((file) => {
        formData.append("documents", file.originFileObj);
      });

      this.setState({
        loadingButton: true,
      });

      await uploadDocumentService.uploadBpkb(formData).then(async (data) => {
        Modal.success({
          title: "Process Success",
          content: "SUCCESS",
        });

        this.setState(this.initState);
      });
    } catch (error) {
      this.setState({
        loadingButton: false,
      });
      Modal.error({
        title: "Process Error",
        content: error.message,
      });
    }
  };

  getYears = (lastXyear = 5) => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: lastXyear }, (v, i) => currentYear - i);
  };

  render() {
    const {
      fileList,
      uploading,
      loadingButton,
      // inputCategory,
      inputDocYear,
      resultBpkbNumber,
      resultPlateNumber,
      resultVehicleColor,
      resultEngineNumber,
      resultChassisNumber,
      resultCustomerName,
      resultCustomerIdNumber,
      resultPhoneNumber,
      resultEmail,
      resultAddress,
      resultCategory,
      resultBrandName,
      resultVariantCode,
      resultManufactureYear,
      editMode,
      inputReason,
    } = this.state;
    const years = this.getYears();

    return (
      <React.Fragment>
        <MainLayout defaultOpenKeys={["m-document"]} defaultSelectedKeys={["m-upload-bpkb"]}>
          <div style={{ padding: 10, margin: 10, background: "#FFF" }}>
            <Form ref={this.formInput} name="form-filter" layout="vertical">
              <Row>
                <Col>
                  <Row>
                    {/* <Col style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <Form.Item label="Kategori">
                        <Input type="hidden" value={inputCategory} onChange={(event) => this.handleFieldChange("inputCategory", event.target.value)} disabled />
                      </Form.Item>
                    </Col> */}
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <Form.Item label="Tahun BPKB">
                        <Select placeholder="Select Year" style={{ width: 200 }} onChange={(value) => this.handleFieldChange("inputDocYear", value)} value={inputDocYear}>
                          {years.map((year) => (
                            <Select.Option key={year} value={year}>
                              {year}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <Form.Item label="&nbsp;">
                        <Button type="primary" onClick={this.onExtractDokumen} disabled={fileList.length === 0} loading={loadingButton} style={{ background: "#1784b8", borderColor: "#1784b8" }}>
                          {uploading ? "Uploading" : "Extract Images"}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }}>
                  <strong>Upload Dokumen</strong>
                  <hr />

                  <Space.Compact>
                    <Upload
                      multiple
                      listType="picture-card"
                      fileList={fileList}
                      onChange={this.onChangeFileList}
                      beforeUpload={() => false} // Prevent auto upload
                      accept="image/*"
                    >
                      <span>
                        <PlusOutlined />
                        <br />
                        <strong>Upload</strong>
                      </span>
                    </Upload>
                  </Space.Compact>
                </Col>
                <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 16 }} lg={{ span: 16 }} md={{ span: 12 }} sm={{ span: 24 }}>
                  <strong>Hasil Extract Dokumen</strong>
                  <hr />
                  <Row>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 10 }} sm={{ span: 24 }}>
                      <Form.Item label="Nama Konsumen">
                        <Input value={resultCustomerName} onChange={(event) => this.handleFieldChange("resultCustomerName", event.target.value)} disabled={!editMode} />
                      </Form.Item>
                      <Form.Item label="Nomor KTP Konsumen">
                        <Input value={resultCustomerIdNumber} onChange={(event) => this.handleFieldChange("resultCustomerIdNumber", event.target.value)} disabled={!editMode} />
                      </Form.Item>
                      <Form.Item label="Nomor Telepon">
                        <Input value={resultPhoneNumber} onChange={(event) => this.handleFieldChange("resultPhoneNumber", event.target.value)} disabled={!editMode} />
                      </Form.Item>
                      <Form.Item label="Email">
                        <Input value={resultEmail} onChange={(event) => this.handleFieldChange("resultEmail", event.target.value)} disabled={!editMode} />
                      </Form.Item>
                      <Form.Item label="Alamat">
                        <Input.TextArea onChange={(event) => this.handleFieldChange("resultAddress", event.target.value)} disabled={!editMode} value={resultAddress} />
                      </Form.Item>
                    </Col>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 16 }} lg={{ span: 16 }} md={{ span: 14 }} sm={{ span: 24 }}>
                      <Row>
                        <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                          <Form.Item label="No BPKB">
                            <Input value={resultBpkbNumber} onChange={(event) => this.handleFieldChange("resultBpkbNumber", event.target.value)} disabled={true} />
                          </Form.Item>
                        </Col>
                        <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                          <Form.Item label="Nomor Polisi">
                            <Input value={resultPlateNumber} onChange={(event) => this.handleFieldChange("resultPlateNumber", event.target.value)} disabled={!editMode} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                          <Form.Item label="Nomor Mesin">
                            <Input value={resultEngineNumber} onChange={(event) => this.handleFieldChange("resultEngineNumber", event.target.value)} disabled={true} />
                          </Form.Item>
                        </Col>
                        <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                          <Form.Item label="Nomor Rangka">
                            <Input value={resultChassisNumber} onChange={(event) => this.handleFieldChange("resultChassisNumber", event.target.value)} disabled={!editMode} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                          <Form.Item label="Kategori Kendaraan">
                            <Input value={resultCategory} onChange={(event) => this.handleFieldChange("resultCategory", event.target.value)} disabled={!editMode} />
                          </Form.Item>
                        </Col>
                        <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                          <Form.Item label="Merk Kendaraan">
                            <Input value={resultBrandName} onChange={(event) => this.handleFieldChange("resultBrandName", event.target.value)} disabled={!editMode} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                          <Form.Item label="Variant Kendaraan">
                            <Input value={resultVariantCode} onChange={(event) => this.handleFieldChange("resultVariantCode", event.target.value)} disabled={!editMode} />
                          </Form.Item>
                        </Col>
                        <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                          <Form.Item label="Warna Kendaraan">
                            <Input value={resultVehicleColor} onChange={(event) => this.handleFieldChange("resultVehicleColor", event.target.value)} disabled={!editMode} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                          <Form.Item label="Alasan Edit">
                            <Input.TextArea onChange={(event) => this.handleFieldChange("inputReason", event.target.value)} disabled={!editMode} value={inputReason} />
                          </Form.Item>
                        </Col>
                        <Col style={{ paddingLeft: 5, paddingRight: 5 }} xs={{ span: 24 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }}>
                          <Form.Item label="Tahun Kendaraan">
                            <Input value={resultManufactureYear} onChange={(event) => this.handleFieldChange("resultManufactureYear", event.target.value)} disabled={!editMode} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <Button onClick={() => this.onActiveEdit()} type="primary" icon={<EditOutlined />} style={{ background: "#ca2e2e", borderColor: "#ca2e2e", display: this.state.editMode ? "none" : "block" }} loading={this.state.loadingButton}>
                        Edit Data
                      </Button>
                      <Button onClick={() => this.onCancelEdit()} type="primary" icon={<CloseOutlined />} style={{ background: "#ca2e2e", borderColor: "#ca2e2e", display: this.state.editMode ? "block" : "none" }} loading={this.state.loadingButton}>
                        Batal Edit
                      </Button>
                    </Col>
                    <Col style={{ paddingLeft: 5, paddingRight: 5 }}>
                      <Button type="primary" onClick={this.onSaveData} loading={loadingButton} style={{ background: "#1784b8", borderColor: "#1784b8" }}>
                        Simpan Dokumen
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </MainLayout>
      </React.Fragment>
    );
  }
}

export default withAuth(DocumentsUploadBpkb);
