// App.js
import React from 'react';
// import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from './screens/Login';
import Dashboard from './screens/Dashboard';
import ListOrder from './screens/ListOrder';
import DocumentsUpload from './screens/DocumentsUpload';
import DocumentsUploadNotice from './screens/DocumentsUploadNotice';
import DocumentsUpdateNumber from './screens/DocumentsUpdateNumber';
import ServiceBureauUploadInvoice from './screens/ServiceBureauUploadInvoice';
// import ServiceBureauPaymentInvoice from './screens/ServiceBureauPaymentInvoice';
// import ServiceBureauInvoiceRepayment from './screens/ServiceBureauRepaymentInvoice';
import ServiceCostUploadInvoice from './screens/ServiceCostUploadInvoice';
import TaxSamsatPayment from './screens/TaxSamsatPayment';
import TaxSamsatPaymentRetry from './screens/TaxSamsatPaymentRetry';
import ProcessCostPayment from './screens/ProcessCostPayment';
import PkbBillPayment from './screens/PkbBillPayment';
import DocumentsUploadBpkb from './screens/DocumentsUploadBpkb';
import DocumentsUploadBpkbRetry from './screens/DocumentsUploadBpkbRetry';




const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" caseSensitive={false} element={<Login />} />
        <Route path="/login" caseSensitive={false} element={<Login />} />
        <Route path="/:code_svb/dashboard" caseSensitive={false} element={<Dashboard />} />
        <Route path="/:code_svb/order/list-order" caseSensitive={false} element={<ListOrder />} />

        <Route path="/:code_svb/document/upload" caseSensitive={false} element={<DocumentsUpload />} />
        <Route path="/:code_svb/document/upload/notice" caseSensitive={false} element={<DocumentsUploadNotice />} />
        <Route path="/:code_svb/document/upload/bpkb" caseSensitive={false} element={<DocumentsUploadBpkb />} />
        <Route path="/:code_svb/document/upload/bpkb/retry" caseSensitive={false} element={<DocumentsUploadBpkbRetry />} />

        <Route path="/:code_svb/document/update/document-number" caseSensitive={false} element={<DocumentsUpdateNumber />} />

        <Route path="/:code_svb/service-bureau/invoice-upload" caseSensitive={false} element={<ServiceBureauUploadInvoice />} />
        {/* <Route path="/:code_svb/service-bureau/invoice-payment" caseSensitive={false} element={<ServiceBureauPaymentInvoice />} /> */}
        {/* <Route path="/:code_svb/service-bureau/invoice-repayment" caseSensitive={false} element={<ServiceBureauInvoiceRepayment />} /> */}

        <Route path="/:code_svb/service-bureau/invoice-service-cost/upload" caseSensitive={false} element={<ServiceCostUploadInvoice />} />

        <Route path="/:code_svb/service-bureau/payment/tax-samsat" caseSensitive={false} element={<TaxSamsatPayment />} />
        <Route path="/:code_svb/service-bureau/payment/tax-samsat/retry" caseSensitive={false} element={<TaxSamsatPaymentRetry />} />
        <Route path="/:code_svb/service-bureau/payment/process-cost" caseSensitive={false} element={<ProcessCostPayment />} />
        <Route path="/:code_svb/service-bureau/payment/pkb-bill" caseSensitive={false} element={<PkbBillPayment />} />

      </Routes>
    </Router>
  );
};

export default App;
